/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 insta.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Insta(props) {
  const { nodes, materials } = useGLTF('/media/models/insta.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Curve002.geometry} material={materials.Material} position={[0, -0.555, -0.539]} rotation={[0, 0, Math.PI / 2]} scale={29.007} />
    </group>
  )
}

useGLTF.preload('/media/models/insta.glb')
