/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 laikka.glb 
*/

import React, { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { Color } from 'three';

export function Laikka(props) {
  const { nodes, materials } = useGLTF('/media/models/laikka.glb')

  useEffect(() => {
    materials.Material.color = new Color(0xffffff);
    materials.Material.roughness = .21;
  }, [materials.Material]);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Laikka.geometry} material={materials.Material} />
    </group>
  )
}

useGLTF.preload('/media/models/laikka.glb')
