/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 heart.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Heart(props) {
  const { nodes, materials } = useGLTF('/media/models/heart.glb')
  return (
    <group {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
        <group position={[0, -16.9, -173.706]} rotation={[Math.PI / 2, 0, -Math.PI / 1]} scale={200}>
          <mesh geometry={nodes.Mesh_0002.geometry} material={materials.body} />
          <mesh geometry={nodes.Mesh_0002_1.geometry} material={materials.eyes} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/media/models/heart.glb')
