/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 type.glb 
*/

import React, { useEffect, useState, useContext } from 'react'
import { useGLTF } from '@react-three/drei'
import { Color } from 'three';
import { isMobileContext } from '../../components/ThreeDContainer/ThreeDContainer';

export function Type(props) {
  const { nodes, materials } = useGLTF('/media/models/type.glb')

  const COLOR_MOBILE = new Color(0x00ff00);
  const COLOR_DESKTOP = new Color(0x003300);

  const [color, setColor] = useState(COLOR_DESKTOP);

  const ctx = useContext(isMobileContext);

  useEffect(() => {
    const isMobile = ctx ? ctx.isMobile : false; 
    setColor(isMobile ? COLOR_MOBILE : COLOR_DESKTOP);
  }, [ctx]);

  useEffect(() => {
    materials.Material.color = color;
    materials.Material.roughness = .21;
  }, [materials.Material, color]);

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.type.geometry} material={materials.Material} rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={5.021} />
    </group>
  )
}

useGLTF.preload('/media/models/type.glb')
