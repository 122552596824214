import React, { useRef, useMemo } from 'react';
import { createNoise2D } from 'simplex-noise';
import { useFrame } from '@react-three/fiber';

const Strobe = ({position, baseIntensity, maxIntensity}) => {

    // strobe
    const baseLightIntensity = baseIntensity;
    const pointLightRef = useRef();
    const noise2D = useMemo(() => createNoise2D(), []);
    useFrame(({ clock }) => {
        const t = clock.getElapsedTime();
        const noiseX = noise2D(t * 14.2, 1) * maxIntensity;
        const noiseY = noise2D(t * 0.01, 1);
        // Apply noise to the head's rotation
        pointLightRef.current.intensity = baseLightIntensity + noiseX * noiseY;
    });


    return (
        <group>
            <pointLight intensity={baseIntensity} position={position} ref={pointLightRef} />
        </group>
    )
}

export default Strobe