import React from 'react';
import {ThreeDContainer} from '../ThreeDContainer/ThreeDContainer';
import Footer from '../Footer/Footer';

const WhoIWhy = () => {

  return (
    <>
      <ThreeDContainer heightPercent={95} />
      <Footer />
    </>
  )
};


export { WhoIWhy };