import React, { useState, useEffect, useRef, forwardRef, useContext, createContext } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { WhoIWhyFace } from '../../assets/WhoiwhyFace/Whoiwhy_face_v1';
import { Laikka } from '../../assets/Laikka/Laikka';
import { Insta } from '../../assets/Insta/Insta'
import { Car } from '../../assets/Car/Car'
import { Horse } from '../../assets/Horse/Horse_noanim'
import { Ocean } from '../../assets/Ocean/Ocean'
// import { Shroom } from '../../assets/Shroom/Shroom'
import { Heart } from '../../assets/Heart/Heart'
import { At } from '../../assets/At/At'
import { Flash } from '../../assets/Flash/Flash';
import { Type } from '../../assets/Type/Type';
import WobblyClickableMesh from '../WobblyClickableMesh/WobblyClickableMesh';
import Strobe from '../Strobe/Strobe';
import { useWindowSize } from '@uidotdev/usehooks';

import './ThreeDContainer.css';

const openLink = (href) => window.open(href, '_blank', 'noopener,noreferrer');

const mapRange = (val, min_in, max_in, min_out, max_out) => {
    if (val <= min_in) return min_out;
    if (val >= max_in) return max_out;
    const ratio_in = (val - min_in) / (max_in - min_in);
    return min_out + ratio_in * (max_out - min_out);
}
const uniVec = val => [val, val, val];

const calcDesktopScale = ({ width, height }) => {
    // 1 for 16/9 and more wide
    // interpolate in [MIN_SCALE, 1] for [1, 16/9[
    const MIN_SCALE = .769;
    const aspectRatio = width / height;
    if (aspectRatio >= 16 / 9) return 1;
    return mapRange(aspectRatio, 1, 16 / 9, MIN_SCALE, 1);
}

const isMobileContext = createContext();

const DEFAULT_WIN_DIMS = {width: 1080, height: 1920};

const ThreeDContainer = ({ heightPercent = 100 }) => {

    // window dims hook
    const windowDims = useWindowSize();

    const [windowDimensions, setWindowDimensions] = useState(DEFAULT_WIN_DIMS);
    const [isMobile, setIsMobile] = useState(false);
    const [desktopScale, setDesktopScale] = useState(calcDesktopScale(windowDimensions));
    const [mobileScale, setMobileScale] = useState(.5);
    const [strobe, setStrobe] = useState(false);

    // update window dims state using hook
    useEffect(() => {
        if(!windowDims.width || !windowDims.height){
            setWindowDimensions(DEFAULT_WIN_DIMS);
            return;
        }
        setWindowDimensions(windowDims);
    }, [windowDims]);

    // update desktop scale
    useEffect(() => {
        if(isMobile) setDesktopScale(calcDesktopScale(windowDimensions));
        console.log(windowDimensions);
    }, [windowDimensions, isMobile, calcDesktopScale]);

    // update isMobile (aspect ratios of 1 and less)
    useEffect(() => {
        const aspectRatio = windowDimensions.width / windowDimensions.height;
        setIsMobile(aspectRatio < 1);
    }, [windowDimensions])

    // lookat target
    const Target = forwardRef((props, ref) => {

        const [pos, setPos] = useState([10, 0, -5])

        // mouse tracking
        const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
        useEffect(() => {
            const handleMouseMove = (event) => {
                setMousePosition({ x: event.clientX, y: event.clientY });
            };

            window.addEventListener('mousemove', handleMouseMove);

            return () => {
                window.removeEventListener('mousemove', handleMouseMove);
            };
        }, []);
        // Convert mouse position to range [0, 1]
        const x = (mousePosition.x / windowDimensions.width);
        const y = 1 - (mousePosition.y / windowDimensions.height);

        //Update the target position of the target based on mouse coordinates
        useFrame(() => {
            const newY = -5 + y * 10;
            const newZ = 5 - x * 10;
            setPos([10, newY, newZ])
        });

        return (
            <mesh ref={ref} position={pos} ></mesh>
        );
    });
    const targetRef = useRef();

    return (
        <isMobileContext.Provider value={{isMobile}}>
            <div
                className='threeDContainer'
                style={{
                    height: `${heightPercent}vh`,
                }}
            >
                <Canvas
                    camera={{ position: [8.4, 0, 0], fov: 69 }}
                    style={{
                        backgroundColor: '#000000',
                        width: '100vw',
                        height: `${heightPercent}vh`,
                    }}
                >
                    <group scale={isMobile ? mobileScale : desktopScale}>

                        <ambientLight intensity={0.0242} />
                        {/* strobes */}
                        {
                            strobe && (<>
                                <Strobe 
                                    position={[10, 10, 10]} 
                                    baseIntensity={.42} 
                                    maxIntensity={6.9} 
                                />
                                <Strobe 
                                    position={[20, -10, -10]} 
                                    baseIntensity={.21} 
                                    maxIntensity={5.42} 
                                />
                            </>)
                        }
                        {/* no strobe zone */}
                        {
                            !strobe && (<>
                                <pointLight 
                                    position={isMobile 
                                        ? [12.1, -4.2, 4.2]
                                        : [12.1, -4.2, 4.2]
                                    } 
                                    intensity={.69} 
                                />
                            </>)
                        }
                        {/* base strobe */}
                        {
                            !strobe && (
                                <Strobe 
                                    position={[-42, 0, 0]} 
                                    baseIntensity={2.42} 
                                    maxIntensity={2.69} 
                                />
                            )
                        }

                        <group className={'type'}>
                            <Type
                                position={isMobile
                                    ? [0, 9, 0]
                                    : [-42, 0, 0]
                                }
                                scale={isMobile
                                    ? uniVec(3.33)
                                    : uniVec(desktopScale * desktopScale * 42)
                                }
                            />
                        </group>

                        <group className={'Face'}>
                            <WhoIWhyFace 
                                position={isMobile
                                    ? [0, -8.88, 0]
                                    : [0, -.69, 0]
                                } 
                                scale={isMobile
                                    ? uniVec(.42)
                                    : uniVec(.84)
                                } 
                                target={targetRef} 
                            />
                            <Target ref={targetRef} />
                        </group>

                        <group className={'Links'}>
                            {/* Heart // FF Animination */}
                            <WobblyClickableMesh 
                                position={isMobile
                                    ? [0, .11, -3.33]
                                    : [0, 2.22, -5]
                                } 
                                scale={isMobile
                                    ? uniVec(8.4)
                                    : uniVec(6.9)
                                } 
                                onclick={() => openLink('https://youtu.be/GIJdxBY6SCg')}
                            >
                                <Heart position={[0, 0, 0]} scale={[.1, .1, .1]} />
                            </WobblyClickableMesh>
                            {/* Car */}
                            <WobblyClickableMesh 
                                position={isMobile 
                                    ? [0, -1.11, 2.84]
                                    : [0, -3.33, 4.2]
                                } 
                                scale={isMobile
                                    ? uniVec(.96)
                                    : uniVec(.96)} 
                                onclick={() => openLink('https://vimeo.com/923771948')}
                            >
                                <Car position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>
                            {/* No Horse Zone */}
                            <WobblyClickableMesh 
                                position={isMobile 
                                    ? [0, 3.3, 3.3]
                                    : [0, 3.3, 5.55]
                                } 
                                scale={isMobile
                                    ? uniVec(.555)
                                    : uniVec(.396)
                                } 
                                onclick={() => openLink('https://youtu.be/ZhJY3AXj824')}>
                                <Horse position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>
                            {/* Laikka */}
                            <WobblyClickableMesh 
                                position={isMobile 
                                    ? [0, 5.55, -2]
                                    : [0, 4.2, 0.169]
                                } 
                                scale={isMobile
                                    ? uniVec(1.21)
                                    : uniVec(1.21)
                                } 
                                onclick={() => openLink('https://www.whereislaikka.com')}
                            >
                                <Laikka position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>

                            {/* Ocean */}
                            {!isMobile ?? <WobblyClickableMesh position={[0, .42, -6.9]} scale={[.42, .42, .42]} onclick={() => openLink('https://vimeo.com/844684360')}>
                                <Ocean position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>}
                            {/* Mail */}
                            <WobblyClickableMesh 
                                position={isMobile
                                    ? [0, -8.88, 3.33]
                                    : [0, -4.2, 0]
                                } 
                                scale={isMobile
                                    ? uniVec(5)
                                    : uniVec(5)
                                } 
                                onclick={() => openLink('mailto:mo@whoiwhy.com')}
                            >
                                <At position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>
                            
                            {/* Insta */}
                            <WobblyClickableMesh 
                                position={isMobile
                                    ? [0, -8.88, -3.33]
                                    : [0, -2.69, -4.69]
                                } 
                                scale={isMobile
                                    ? uniVec(1.69)
                                    : uniVec(1.69)
                                } 
                                onclick={() => openLink('https://www.instagram.com/who.i.why')}
                            >
                                <Insta position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>

                            {/* Strobe */}
                            <WobblyClickableMesh 
                                position={isMobile
                                    ? [0, -4.84, 0]
                                    : [0, -.1, 5]
                                } 
                                scale={isMobile
                                    ? uniVec(.77)
                                    : uniVec(.69)
                                } 
                                onclick={() => setStrobe(!strobe)}
                            >
                                <Flash position={[0, 0, 0]} scale={[1, 1, 1]} />
                            </WobblyClickableMesh>

                            {/* Shroom */}
                            {/* <WobblyClickableMesh position={[0, 3.33, -6.9]} scale={[6.9, 6.9, 6.9]} onclick={() => openLink('https://vimeo.com/840408588')}>
                            <Shroom position={[0, 0, 0]} scale={[1, 1, 1]} /></WobblyClickableMesh> */}
                        </group>

                    </group>
                </Canvas>
            </div>
        </isMobileContext.Provider>
    );
};



export {ThreeDContainer, isMobileContext};