/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 horse_noanim.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Horse(props) {
  const { nodes, materials } = useGLTF('/media/models/horse_noanim.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 0]} rotation={[-2.59, 1.566, -0.335]} scale={0.01}>
        <group position={[0, 0, 0]} rotation={[Math.PI / 2, 0, 0]} scale={100}>
          <mesh geometry={nodes.mm_frame003.geometry} material={materials['BODY.002']} />
          <mesh geometry={nodes.mm_frame003_1.geometry} material={materials['HEAD.002']} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/media/models/horse_noanim.glb')
