import React, { useMemo, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import {createNoise2D} from 'simplex-noise';

const WobblyClickableMesh = ({ position, scale, rotation, onclick, children }) => {

    const groupRef = useRef();

    // npc movement
    // Initialize Perlin noise or Simplex noise generator
    const noise2D = useMemo(() => createNoise2D(), []);
    useFrame(({ clock }) => {
        const t = clock.getElapsedTime();
        // Generate noise for each rotation axis
        // Adjust the scale (0.5, 0.3, etc.) and speed (t * 0.2) as needed for your effect
        const noiseX = noise2D(t * 0.42, 4) * 0.069;
        const noiseY = noise2D(t * 0.21, 5) * 0.0069;
        const noiseZ = noise2D(t * 0.1, 6) * 0.42;
        // Apply noise to the head's rotation
        groupRef.current.rotation.x += noiseX * 0.01;
        groupRef.current.rotation.y += noiseY * 0.01;
        groupRef.current.rotation.z += noiseZ * 0.01;
    });

    return (
        <group position={position} scale={scale} rotation={rotation} dispose={null} ref={groupRef} onClick={onclick}>
            {children}
        </group>
    );
};

export default WobblyClickableMesh;