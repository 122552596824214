/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx whoiwhy_face_v1.glb 
*/

import React, { useRef, useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import { createNoise2D } from 'simplex-noise';

export const WhoIWhyFace = ({ position, scale, rotation, target }) => {
  const { nodes, materials } = useGLTF('/media/models/whoiwhy_face_v1.glb')

  const headRef = useRef();
  const leftEyeRef = useRef();
  const rightEyeRef = useRef();

  // target tracking
  useFrame(() => {
    if (target.current) {
      const targetPosition = target.current.position;
      leftEyeRef.current.lookAt(targetPosition);
      rightEyeRef.current.lookAt(targetPosition);

      leftEyeRef.current.rotateY(Math.PI / 2);
      rightEyeRef.current.rotateY(Math.PI / 2);
    }
  });

  // npc movement
  // Initialize Perlin noise or Simplex noise generator
  const noise2D = useMemo(() => createNoise2D(), []);
  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    // Generate noise for each rotation axis
    // Adjust the scale (0.5, 0.3, etc.) and speed (t * 0.2) as needed for your effect
    const noiseX = noise2D(t * 0.42, 1) * 0.001;
    const noiseY = noise2D(t * 0.21, 2) * 0.01;
    const noiseZ = noise2D(t * 0.1, 3) * 0.021;
    // Apply noise to the head's rotation
    headRef.current.rotation.x += noiseX * 0.01;
    headRef.current.rotation.y += noiseY * 0.01;
    headRef.current.rotation.z += noiseZ * 0.01;
  });

  return (
    <group position={position} scale={scale} dispose={null}>
      <mesh geometry={nodes.Head.geometry} material={materials.defaultMat} ref={headRef}>
        <mesh geometry={nodes.EyeL.geometry} material={materials['defaultMat.001']} position={[1.98, 0.64, 0.64]} rotation={[0, .5, -0.086]} scale={0.622} ref={leftEyeRef}/>
        <mesh geometry={nodes.EyeR.geometry} material={materials['defaultMat.002']} position={[1.96, 0.64, -0.64]} rotation={[0, -.5, -0.086]} scale={0.622} ref={rightEyeRef}/>
      </mesh>
    </group>
  )
}

useGLTF.preload('/media/models/whoiwhy_face_v1.glb')
