import './App.css';
import { WhoIWhy } from './components/WhoIWhy/WhoIWhy';


const App = () => {

  return (
    <div className="App">
        <WhoIWhy />
    </div>
  );
};

export default App;